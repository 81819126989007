import React, { useState } from 'react';
import { Trans } from '@lingui/macro'
import Modal from '../Modal';
import { Text } from 'rebass';
import { ButtonError } from '../Button'
import styled from 'styled-components';

const ConfirmationModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 10px; /* Added margin at the bottom for spacing */
`;

const ConfirmationModalTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const ConfirmationModalInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
`;

const ConfirmationModal = ({ isOpen, onDismiss, onConfirm }) => {
    const [confirmationInput, setConfirmationInput] = useState('');

    const handleConfirm = () => {
        const confirmWord = 'confirm';  // The word to confirm
        if (confirmationInput === confirmWord) {
            onConfirm();
            onDismiss();
        } else {
            alert('Incorrect confirmation word');
        }
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={110}>
            <ConfirmationModalWrapper>
                <ConfirmationModalTitle>Please type the word "confirm" to enable expert mode:</ConfirmationModalTitle>
                <ConfirmationModalInput
                    type="text"
                    value={confirmationInput}
                    onChange={(e) => setConfirmationInput(e.target.value)}
                />
               <br></br>
                <ButtonError onClick={handleConfirm}>
                    <Text fontSize={20} fontWeight={500}>
                        <Trans>Confirm</Trans>
                    </Text>
                </ButtonError>
            </ConfirmationModalWrapper>
        </Modal>
    );
};

export default ConfirmationModal;
